<template>
    <Form :data="data" @submit="submit">
        <template #success>
            You email address has been<br> successfully updated
        </template>
    </Form>
</template>


<script>
import Form from '@/components/auth/Form'
import { reactive } from '@vue/reactivity'
import { getValidationError, clearValidationError } from '@/helpers/objects'
import api from '@/services/user/user.service.js'
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from 'vue-router'

export default {
    props: [''],
    components: {
        Form
    },
    setup() {
        const router = useRouter()

        const data = reactive({
            step: 'form',
            successStepClass: 'bold',
            title: 'Update email address',
            inputs: [
                { label: 'Please enter your new email address', placeholder: 'Enter new email', value: '', key: 'email', type: 'text', error: ''},
            ],
            btn: {
                title: 'Update email address',
                type: 'primary',
                icon: 'fas fa-sign-in-alt',
                loading: false,
            }
        })

        async function submit() {
            clearValidationError()
            const result = await api.changeEmail({email: data.inputs[0].value})

            data.btn.loading = false

            if(result.errors) {
                getValidationError(result.errors, data.inputs)
                return
            }
            showSuccesssStep()

        }

        function showSuccesssStep() {
            data.step = 'success'
            delete data.title
            delete data.btn

            setTimeout(() => {
                router.push('/my-estimates')
            }, 2000)
        }

        return {
            data,
            submit
        }
    }
}
</script>


<style scoped lang="scss">
::v-deep .auth-form {
    min-height: 257px;
    padding: 40px 35px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .auth-form__success {
    width: 100%;
}
@media (max-width: 768px) {
  ::v-deep .auth-form {
    padding: 30px 20px;
  }
}
</style>
